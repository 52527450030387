import React from 'react'

const ContactUsFooter = () => {
    return (
        <div className="col-sm-6 col-lg-4 col-xl-4">
            <div className="oh-desktop">
                <div className="inset-top-18 wow slideInDown" data-wow-delay="0s">
                    <h5>Ask</h5>
                    <p>Ask us about any thing related to Agriculture</p>
                    <div className="rd-form rd-mailform" data-form-output="form-output-global" data-form-type="subscribe">
                        <div className="form-wrap">
                            <input className="form-input" id="subscribe-form-5-email" type="email" name="email" data-constraints="@Email @Required" />
                            <label className="form-label" htmlFor="subscribe-form-5-email">Email</label>
                        </div>
                        <div className="form-wrap">
                            <input className="form-input" id="subscribe-form-5-text" type="text" name="text" data-constraints="@Required" />
                            <label className="form-label" htmlFor="subscribe-form-5-text">Question ?</label>
                        </div>
                        <button className="button button-block button-white" onClick={() => {
                            const email = document.getElementById("subscribe-form-5-email").value;
                            const question = document.getElementById("subscribe-form-5-text").value;

                            if(email.length !== 0 && question.length !== 0) {
                                window.sendEmail({
                                    To : 'question@greenbash.net',
                                    From : email,
                                    Subject : "Ask",
                                    Body : `${question} ?`
                                })
                            }
                            else {
                                alert("Please fill the ask form")
                            }
                        }}>Submit</button>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ContactUsFooter;