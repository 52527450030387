import React from 'react'

const Card = ({ name, type, email, number, profile }) => {
    return (
        <div style={{backgroundColor: "#ecf0f1"}} className="rounded-lg p-6">
            <img className="h-16 w-16 md:h-24 md:w-24 rounded-full mx-auto md:mx-0" src={profile} />
            <div className="text-center md:text-left">
                <div className="text-lg" style={{textDecoration: 'underline'}}>{name}</div>
                <div className="text-purple-500">{type}</div>
                <div className="text-grey-600">{email}</div>
                <div className="text-grey-600">{number}</div>
            </div> 
        </div>
    )
}

export default Card;