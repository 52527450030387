import React from 'react'
import Preview from './Preview/Preview'
import Info from './Info/Info'
import Staff from './Staff/Staff'

const ContactUs = () => {
  document.title = "Green Bash - Contact Us"
  return (
    <div>
      <Preview />
      <Info />
      <Staff />
    </div>
  )
}

export default ContactUs;