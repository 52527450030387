import React, { Component } from 'react'
import MostOrderOnline from './MostOrderOnline/MostOrderOnline'
import ExtraOrderOnline from './ExtraOrderOnline/ExtraOrderOnline'

const OrderOnlineData = async () => {
    return (await fetch('https://greenbashapi.herokuapp.com/homeorderonline')).json()
}

class OrderOnline extends Component {
    constructor(props) {
        super(props)
        this.state = {
            orderonlinedata: []
        }
    }

    componentDidMount() {
        Promise.resolve(OrderOnlineData()).then(result => { this.setState({ orderonlinedata: result }) })
    }

    render() {
        return (
            <div>
                <MostOrderOnline ins={this.props.ins} orderonlinedata={this.state.orderonlinedata} />
                <ExtraOrderOnline ins={this.props.ins} orderonlinedata={this.state.orderonlinedata} />
            </div>
        )
    }
}

export default OrderOnline;;