import React, { Component } from 'react'

const WhyChooseUsData = async () => {
    return (await fetch('https://greenbashapi.herokuapp.com/whychooseus')).json()
}

class WhyChooseUs extends Component {
    constructor(props) {
        super(props)
        this.state = {
            whychooseusdata: []
        }
    }

    componentDidMount() {
        Promise.resolve(WhyChooseUsData()).then(result => { this.setState({ whychooseusdata: result }) })
    }
    
    render() {
        return (
            <section className="section section-md section-first bg-default text-md-left">
                <div className="container">
                <div className="row row-50 justify-content-center">
                    <div className="col-md-10 col-lg-5 col-xl-6"><img src={this.state.whychooseusdata.logo_img} alt="" width="519" height="446"/>
                    </div>
                    <div className="col-md-10 col-lg-7 col-xl-6">
                    <h2>Why Choose Us</h2>
                    <div className="tabs-custom tabs-horizontal tabs-line" id="tabs-4">
                        <ul className="nav nav-tabs">
                            <li className="nav-item" role="presentation"><a className="nav-link active" href="#tabs-4-1" data-toggle="tab">Our Mission</a></li>
                            <li className="nav-item" role="presentation"><a className="nav-link" href="#tabs-4-2" data-toggle="tab">Our Vision</a></li>
                        </ul>
                        <div className="tab-content">
                        <div className="tab-pane fade show active" id="tabs-4-1">
                            {/* <p>Our commitments are to provide our clients with the most innovative, sustainable, and cost-effective Inputs to help them raise their yield level and produce with a degree of high quality.</p> */}
                            {
                                this.state.whychooseusdata.ourmission
                            }
                        </div>
                        <div className="tab-pane fade" id="tabs-4-2">
                            {/* <p>To become one of the Agricultural inputs supplier’s leader in the local and regional market.</p>
                            <p>To provide a unique farm solution in the area of Agrochemicals, Fertilizers, harvesting consumables, seeds, farm equipment and tools.</p> */}
                            {
                                <span>{this.state.whychooseusdata.ourvision}</span>
                            }
                        </div>
                        </div>
                    </div>
                    </div>
                </div>
                </div>
            </section>
        )
    }
}

export default WhyChooseUs;