import React, { Component } from 'react'
import ContactUsFooter from './ContactUsFooter/ContactUsFooter'
import AboutUsFooter from './AboutUsFooter/AboutUsFooter'

const ExtraFooter = (currentPage) => {
    if(currentPage === 'ContactUs')
        return <ContactUsFooter />
    else if(currentPage === 'AboutUs')
        return <AboutUsFooter />
}

const LocationsData = async () => {
    return (await fetch('https://greenbashapi.herokuapp.com/locations')).json();
}

class Footer extends Component {
    constructor(props) {
        super(props)
        this.state = {
            locations: []
        }
    }

    componentDidMount() {
        Promise.resolve(LocationsData()).then(result => { this.setState({ locations: result }) });
    }

    render() {
        return (
            <footer className="section footer-variant-2 footer-modern context-dark section-top-image section-top-image-dark">
                <div className="footer-variant-2-content">
                <div className="container">
                    <div className="row row-40 justify-content-between">
                    <div className="col-sm-6 col-lg-4 col-xl-3">
                        <div className="oh-desktop">
                        <div className="wow slideInRight" data-wow-delay="0s">
                            <div className="footer-brand"><a href="Home"><img src="https://i.imgur.com/kVQPieJ.png" alt="" width="196" height="42"/></a></div>
                            <p>Green Bash is located in Khartoum-Sudan.</p>
                            <ul className="footer-contacts d-inline-block d-md-block">
                            <li>
                                <div className="unit unit-spacing-xs">
                                <div className="unit-left"><span className="icon fa fa-phone"></span></div>
                                <div className="unit-body"><a className="link-phone" href="tel:#">+249 1 234 78930</a></div>
                                </div>
                            </li>
                            <li>
                                <div className="unit unit-spacing-xs">
                                <div className="unit-left"><span className="icon fa fa-clock-o"></span></div>
                                <div className="unit-body">
                                    <p>Sat-Thu: 08:00AM - 04:00PM</p>
                                </div>
                                </div>
                            </li>
                            <li>
                                <div className="unit unit-spacing-xs">
                                <div className="unit-left"><span className="icon fa fa-location-arrow"></span></div>
                                <div className="unit-body"><a className="link-location" href="https://goo.gl/maps/kkxeuy8tMsPkfih56">Sheikh Mustafa Alamin Building- El Gamhuriya Street Khartoum-Sudan.</a><a href="https://goo.gl/maps/kkxeuy8tMsPkfih56">عمارة الشيخ مصطفي الأمين - شارع الجمهورية الخرطوم - السودان</a></div>
                                </div>
                            </li>
                            </ul>
                        </div>
                        </div>
                    </div>
    
                    {
                        ExtraFooter(this.props.currentPage)
                    }
    
                    <div className="col-lg-3 col-xl-3">
                        <div className="oh-desktop">
                        <div className="inset-top-18 wow slideInLeft" data-wow-delay="0s">
                            <h4 style={{textDecoration: "underline"}}>Our Locations</h4>
                            <ul className="list-marked" style={{fontSize: "large", fontStyle: "bold", marginTop: "5%"}}>
                                {/* <li>Khartoum-HQ</li>
                                <li>Madani</li>
                                <li>AlDamar</li>
                                <li>Eastern Province</li> */}
                                {
                                    this.state.locations.map((data, index) => {
                                        return <li key={index}>{data.location}</li>
                                    })
                                }
                            </ul>
                        </div>
                        </div>
                    </div>
                    </div>
                </div>
                </div>
                <div className="footer-variant-2-bottom-panel">
                <div className="container">
                    <div className="group-sm group-sm-justify">
                    <p className="rights"><span>&copy;&nbsp;</span><span className="copyright-year"></span> <span>Green Bash</span>. All rights reserved
                    </p>
                    <p className="rights">Design&nbsp;by&nbsp;<a href="DesignedBy">AP</a></p>
                    </div>
                </div>
                </div>
            </footer>
        )
    }
}

export default Footer;