import React from 'react'

const OurPartnerItem = ({ name, flag_img, description_img }) => {
    return (
        <div className="owl-item">
            <article className="thumbnail thumbnail-mary thumbnail-md">
                <div className="thumbnail-mary-figure"><img src={flag_img} alt="" width="420" height="308"/>
                </div>
                <div className="thumbnail-mary-caption"><a className="icon fl-bigmug-line-zoom60" href={description_img} data-lightgallery="item"><img src={flag_img} alt="" width="420" height="308"/></a>
                </div>
            </article>
            <div className="thumbnail-mary-description">
                <h5 className="thumbnail-mary-project"><a href="#">{name}</a></h5><span className="thumbnail-mary-decor"></span>
            </div>
        </div>
    )
}

export default OurPartnerItem;