import React from 'react';
import './App.css'
import Page from './components/Page/Page'
import Loader from './components/Loader/Loader'

const App = () => {
    return (
        <div>
            <Loader />
            <Page />
        </div>
    )
}

export default App;