import React from 'react'

const OurGalleryItem = ({ label, real_img, preview_img }) => {
    return (
        <div className="col-sm-6 col-lg-4">
            <div className="oh-desktop">
                <article className="thumbnail thumbnail-mary thumbnail-sm wow slideInLeft" data-wow-delay="0s">
                    <div className="thumbnail-mary-figure"><img src={preview_img} alt="" width="370" height="303"/>
                    </div>
                    <div className="thumbnail-mary-caption"><a className="icon fl-bigmug-line-zoom60" href={real_img} data-lightgallery="item"><img src={preview_img} alt="" width="370" height="303"/></a>
                        <h4 className="thumbnail-mary-title">{label}</h4>
                    </div>
                </article>
            </div>
        </div>
    )
}

export default OurGalleryItem;