import React from 'react'

const Preview = () => {
    return (
        <section className="breadcrumbs-custom-inset">
            <div className="breadcrumbs-custom context-dark bg-overlay-46">
            <div className="container">
                <h2 className="breadcrumbs-custom-title">Our Products</h2>
                <ul className="breadcrumbs-custom-path">
                    <li><a href="Home">Home</a></li>
                    <li className="active">Our Products</li>
                </ul>
            </div>
            <div className="box-position" style={{backgroundImage: "url(images/products.jpg)"}}></div>
            </div>
        </section>
    )
}

export default Preview;