import React, { Component } from 'react'
import SliderItem from './SliderItem/SliderItem'

const SliderData = async () => {
    return (await fetch('http://localhost:3001/homeslider')).json()
}

class Slider extends Component {
    constructor(props) {
        super(props)
        this.state = {
            sliderdata: []
        }
    }

    componentzMount() {
        Promise.resolve(SliderData()).then(result => { this.setState({ sliderdata: result }) })
    }

    render() {
        return (
            <section className="section swiper-container swiper-slider swiper-slider-modern" data-loop="true" data-autoplay="5000" data-simulate-touch="true" data-nav="true" data-slide-effect="fade">
                <div className="swiper-wrapper text-left">
                    <SliderItem sb_image="images/fertilizers-image.jpg" label="Fertilizers" description="Liquid, Soluble, Powder, Granules & Crystal." />
                    <SliderItem sb_image="images/agrochemical-image.jpg" label="Agrochemicals" description="Herbicides, Insecticides, Fungicides, Seed Dressers & Public Health Pesticides." />
                    <SliderItem sb_image="images/seeds-image.jpg" label="Seeds" description="Alfalfa, Rhodes Grass & Vegetables." />
                </div>
                <div className="swiper-button-prev"></div>
                <div className="swiper-button-next"></div>
                <div className="swiper-pagination swiper-pagination-style-2"></div>
            </section>
        )
    }
}

export default Slider;
{/* <SliderItem sb_image="images/fertilizers-image.jpg" label="Fertilizers" description="Liquid, Soluble, Powder, Granules & Crystal." />
    <SliderItem sb_image="images/agrochemical-image.jpg" label="Agrochemicals" description="Herbicides, Insecticides, Fungicides, Seed Dressers & Public Health Pesticides." />
    <SliderItem sb_image="images/seeds-image.jpg" label="Seeds" description="Alfalfa, Rhodes Grass & Vegetables." /> */ }