import React from 'react'

const Info = () => {
    return (
        <section className="section section-md section-first bg-default">
            <div className="container wow slideInDown">
                <div className="row row-30 justify-content-center">
                    <div className="col-sm-8 col-md-6 col-lg-4">
                    <article className="box-contacts">
                        <div className="box-contacts-body">
                        <div className="box-contacts-icon fl-bigmug-line-cellphone55"></div>
                        <div className="box-contacts-decor"></div>
                        <p className="box-contacts-link">+249 1 234 78930</p>
                        <p className="box-contacts-link">+249 1 234 78935</p>
                        <p className="box-contacts-link">+249 1 234 78940</p>
                        <p className="box-contacts-link">P.o.Box: 11283 Khartoum</p>
                        </div>
                    </article>
                    </div>
                    <div className="col-sm-8 col-md-6 col-lg-4">
                    <article className="box-contacts">
                        <div className="box-contacts-body">
                        <div className="box-contacts-icon fl-bigmug-line-up104"></div>
                        <div className="box-contacts-decor"></div>
                        <p className="box-contacts-link"><div className="unit-body"><a className="link-location" href="https://goo.gl/maps/kkxeuy8tMsPkfih56">Sheikh Mustafa Alamin Building- El Gamhuriya Street Khartoum-Sudan.</a><a href="https://goo.gl/maps/kkxeuy8tMsPkfih56">عمارة الشيخ مصطفي الأمين - شارع الجمهورية الخرطوم - السودان</a></div></p>
                        </div>
                    </article>
                    </div>
                    <div className="col-sm-8 col-md-6 col-lg-4">
                    <article className="box-contacts">
                        <div className="box-contacts-body">
                        <div className="box-contacts-icon fl-bigmug-line-chat55"></div>
                        <div className="box-contacts-decor"></div>
                        <p className="box-contacts-link"><a href="mailto:#">mohamed@greenbash.net</a></p>
                        <p className="box-contacts-link"><a href="mailto:#">info@greenbash.net</a></p>
                        </div>
                    </article>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default Info;