import React from 'react'

const AboutUsFooter = () => {
    return (
        <div className="col-sm-6 col-lg-4 col-xl-4">
            <div className="oh-desktop">
                <div className="inset-top-18 wow slideInDown" data-wow-delay="0s">
                    <h5 style={{textDecoration: "underline"}}>Company Profile</h5>
                    <div className="group-lg group-middle">
                        <ul className="list-marked">
                        <li>Our services and support expand from supplying the most efficient and improved Agricultural inputs up to in the field support with technical guidance.</li>
                        <li>Our Range of services includes fertilizers, pesticides, sprayers, harvesting consumables and seeds.</li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default AboutUsFooter;