import React from 'react'
import Slider from './Silder/Slider'
import OrderOnline from './OrderOnline/OrderOnline'
import OurMission from './OurMission/OurMission'
import OurTeam from './OurTeam/OurTeam'
import Establishment from './Establishment/Establishment'
import OurGallery from './OurGallery/OurGallery'

const Home = ({ ins }) => {
    document.title = "GREEN BASH FOR MULTI ACTIVITIES CO.LTD"
    return (
        <div>
            <Slider />
            <OrderOnline ins={ins} />
            <OurMission />
            <OurTeam />
            <Establishment />
            {/* <OurGallery /> */}
        </div>
    )
}

export default Home;