import React, { Component } from 'react'
import CartItem from './CartItem/CartItem'

const Title = async () => {
    return (await fetch('https://greenbashapi.herokuapp.com/navbardata')).json()
}

class Navbar extends Component {
    constructor(props) {
        super(props)
        this.state = {
            engtitle: '',
            arbtitle: '',
            logo: '',
            show: 'none'
            
        }
    }

    componentDidMount() {
        Promise.resolve(Title()).then(navbardata => { this.setState({ engtitle: navbardata.engtitle, arbtitle: navbardata.arbtitle, logo: navbardata.logo }) })
        if(window.sessionStorage.cart_list !== null && window.sessionStorage.cart_list !== undefined) {
            const cart_list_data = window.sessionStorage.cart_list.split(',')
            cart_list_data.map(async name => {
                const data = await (await fetch(`https://greenbashapi.herokuapp.com/cartinfo/${name}`)).json()
                this.props.cart.push(data[0])
                this.props.ins.setState({ cart: this.props.ins.state.cart })
            })
        }
    }

    render() {
        return (
            <header className="section page-header">
                <div className="rd-navbar-wrap rd-navbar-modern-wrap">
                <nav className="rd-navbar rd-navbar-modern" data-layout="rd-navbar-fixed" data-sm-layout="rd-navbar-fixed" data-md-layout="rd-navbar-fixed" data-md-device-layout="rd-navbar-fixed" data-lg-layout="rd-navbar-static" data-lg-device-layout="rd-navbar-fixed" data-xl-layout="rd-navbar-static" data-xl-device-layout="rd-navbar-static" data-xxl-layout="rd-navbar-static" data-xxl-device-layout="rd-navbar-static" data-lg-stick-up-offset="46px" data-xl-stick-up-offset="46px" data-xxl-stick-up-offset="70px" data-lg-stick-up="true" data-xl-stick-up="true" data-xxl-stick-up="true">
                    <div className="rd-navbar-main-outer">
                    <div className="rd-navbar-main">
                        <div className="rd-navbar-panel">
                        <button className="rd-navbar-toggle" data-rd-navbar-toggle=".rd-navbar-nav-wrap"><span></span></button>
                        <div style={{display: "flex"}}>
                            <div style={{marginTop: "1%"}} className="rd-navbar-brand"><a className="brand" href="Home"><img src={this.state.logo} alt="" width="196" height="47"/></a></div>
                            <div style={{fontWeight: "bolder", display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center"}}>
                                {/* <span style={{fontSize: "small"}}><span style={{color: "forestgreen"}}>GREEN</span> BASH FOR MULTI ACTIVITIES CO.LTD</span>
                                <span style={{color: "#2c3e50", fontSize: "small", textAlign: "right"}}>شركة <span style={{color: "forestgreen"}}>قرين</span> باش للأنشطة المتعددة المحدودة</span> */}
                                <span style={{fontSize: "small"}}>{this.state.engtitle}</span>
                                <span style={{color: "#2c3e50", fontSize: "small", textAlign: "right"}}>{this.state.arbtitle}</span>
                            </div>
                        </div>
                        </div>
                        <div className="rd-navbar-main-element">
                        <div className="rd-navbar-nav-wrap">
                            <div className="rd-navbar-basket-wrap">
                            <button className="rd-navbar-basket fl-bigmug-line-shopping198" data-rd-navbar-toggle=".cart-inline"><span>{this.props.cart.length}</span></button>
                            <div className="cart-inline">
                                <div className="cart-inline-header">
                                <h5 className="cart-inline-title">In cart:<span> {this.props.cart.length}</span> Products</h5>
                                </div>
                                <div className="cart-inline-body" style={{ maxHeight: '40vh', overflowY: 'auto' }}>
                                    {/* <div className="cart-inline-item">
                                        <div className="unit align-items-center">
                                            <div className="unit-left"><a className="cart-inline-figure" href="#"><img src="images/Humu Green2.png" alt="" width="108" height="100"/></a></div>
                                            <div className="unit-body">
                                                <h6 className="cart-inline-name"><a href="#">Humu Green</a></h6>
                                                <div>
                                                <div className="group-xs group-inline-middle">
                                                    <div className="table-cart-stepper">
                                                    <input className="form-input" type="number" data-zeros="true" defaultValue="0" min="0" max="1000" />
                                                    </div>
                                                </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div> */}
                                    {
                                        this.props.cart.map((data, index) => {
                                            if(this.state.show == 'none')
                                                this.setState({ show: 'block' })
                                            return <CartItem key={index} cart_quantity={data.cart_quantity} cart_item_min={data.cart_item_min} cart_item_max={data.cart_item_max} cart_img={data.cart_img} cart_name={data.cart_name} />
                                        })
                                    }
                                </div>
                                <div className="cart-inline-footer">
                                    <div className="group-sm">
                                        <a style={{ display: this.state.show }} className="button button-md button-primary button-pipaluk" href="CustomerForm" onClick={(event) => {
                                            const product_list = this.props.cart.map(data => {
                                                return `${data.cart_name} - ${document.getElementById(data.cart_name).value} ${data.cart_quantity}`
                                            })
                                            window.sessionStorage.clear()
                                            window.sessionStorage.product_list = product_list
                                        }}>Purchase</a>
                                        <a style={{ display: this.state.show }} className="button button-md button-default-outline-2 button-wapasha" href="#clear" onClick={() => {
                                            this.props.ins.setState({ cart: [] })
                                            this.setState({ show: 'none'})
                                            window.sessionStorage.clear()
                                        }}>Clear</a>
                                    </div>
                                </div>
                            </div>
                            </div>                    
                            <ul className="rd-navbar-nav">
                                <li className="rd-nav-item active"><a className="rd-nav-link" href="Home">Home</a>
                                </li>
                                <li className="rd-nav-item"><a className="rd-nav-link" href="OurProducts">Our Products</a>
                                </li>
                                <li className="rd-nav-item"><a className="rd-nav-link" href="ContactUs">Contact Us</a>
                                </li>
                                <li className="rd-nav-item"><a className="rd-nav-link" href="AboutUs">About Us</a>
                                </li>
                            </ul>
                        </div>
                        <div className="rd-navbar-project-hamburger" data-multitoggle=".rd-navbar-main" data-multitoggle-blur=".rd-navbar-wrap" data-multitoggle-isolate>
                            <div className="project-hamburger"><span className="project-hamburger-arrow-top"></span><span className="project-hamburger-arrow-center"></span><span className="project-hamburger-arrow-bottom"></span></div>
                            <div className="project-hamburger-2"><span className="project-hamburger-arrow"></span><span className="project-hamburger-arrow"></span><span className="project-hamburger-arrow"></span>
                            </div>
                            <div className="project-close"><span></span><span></span></div>
                        </div>
                        </div>
                        <div className="rd-navbar-project rd-navbar-modern-project">
                        <div className="rd-navbar-project-modern-header">
                            <h4 className="rd-navbar-project-modern-title">Get in Touch</h4>
                            <div className="rd-navbar-project-hamburger" data-multitoggle=".rd-navbar-main" data-multitoggle-blur=".rd-navbar-wrap" data-multitoggle-isolate>
                            <div className="project-close"><span></span><span></span></div>
                            </div>
                        </div>
                        <div className="rd-navbar-project-content rd-navbar-modern-project-content">
                            <div>
                            <p>We are always ready to provide you with fresh agriculture products. Contact us to find out how we can help you.</p>
                            <ul className="rd-navbar-modern-contacts">
                                <li>
                                    <div className="unit unit-spacing-sm">
                                        <div className="unit-left"><span className="icon fa fa-phone"></span></div>
                                        <div className="unit-body"><a>+249 1 234 78930</a></div>
                                    </div>
                                </li>
                                <li>
                                    <div className="unit unit-spacing-sm">
                                        <div className="unit-left"><span className="icon fa fa-location-arrow"></span></div>
                                        <div className="unit-body"><a>Sheikh Mustafa Alamin Building- El Gamhuriya Street Khartoum-Sudan.عمارة الشيخ مصطفي الأمين - شارع الجمهورية الخرطوم - السودان</a></div>
                                    </div>
                                </li>
                                <li>
                                    <div className="unit unit-spacing-sm">
                                        <div className="unit-left"><span className="icon fa fa-envelope"></span></div>
                                        <div className="unit-body"><a>mail@greenbash.net</a></div>
                                    </div>
                                </li>
                                </ul>
                            </div>
                        </div>
                        </div>
                    </div>
                    </div>
                </nav>
                </div>
            </header>
        )
    }
}

export default Navbar;