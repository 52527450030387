import React, { Component } from 'react'
import OurGalleryItem from './OurGalleryItem/OurGalleryItem'

const OurGalleryData = async () => {
    return (await fetch('https://greenbashapi.herokuapp.com/homegallery')).json()
}

class OurGallery extends Component {
    constructor(props) {
        super(props)
        this.state = {
            ourgallerydata: []
        }
    }

    componentDidMount() {
        Promise.resolve(OurGalleryData()).then(result => { this.setState({ ourgallerydata: result }) })
    }

    render() {
        return (
            <section className="section section-md bg-default section-top-image">
                <div className="container">
                    <div className="oh h2-title">
                        <h2 className="wow slideInUp" data-wow-delay="0s">Our Products Brochures</h2>
                    </div>
                    <div className="row row-30" data-lightgallery="group">
                        {
                            this.state.ourgallerydata.map((data, index) => {
                                return <OurGalleryItem key={index} label={data.label} real_img={data.real_img} preview_img={data.preview_img} />
                            })
                        }
                    </div>
                </div>
            </section>
        )
    }
}

export default OurGallery;