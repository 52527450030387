import React from 'react'
import OurTeam from '../OurTeam'

const OurTeamItem = ({ name, profession, profile_img }) => {
    return (
        <div className="col-md-6 col-lg-4 col-xl-4 wow fadeInRight" data-wow-delay="0s">
            <article className="team-classic"><img src={profile_img} alt="" width="370" height="406"/>
                <div className="team-classic-caption">
                <h5 className="team-classic-name">{name}</h5>
                <p className="team-classic-status" style={{color: "#1abc9c"}}>{profession}</p>
                </div>
            </article>
        </div>
    )
}

export default OurTeamItem;