import React from 'react'

const OurRecentPRoducts = () => {
    return (
        <section className="section context-dark">
            <div className="parallax-container" data-parallax-img="images/orp.jpg">
            <div className="parallax-content section-md bg-overlay-2-21">
                <div className="container">
                <div className="oh">
                    <h2 className="wow slideInUp" data-wow-delay="0s">Our Recent Products</h2>
                </div>
                <div className="owl-carousel owl-modern" data-items="1" data-stage-padding="15" data-margin="30" data-dots="true" data-animation-in="fadeIn" data-animation-out="fadeOut" data-autoplay="true">
                    <article className="quote-lisa">
                    <h2 style={{fontSize: "xx-large", textDecoration: "underline", fontWeight: "bold"}} className="quote-lisa-cite">Fertilizers (Liquid, Soluble, Powder, Granules, Crystal)</h2>
                    <div className="quote-lisa-body">
                        <div className="quote-lisa-text">
                            <p className="q">Water soluble – Compound Suspension – Compound Liquid – Slow Release – Chelated Micro Nutrients complex & Single – Humic Acid Based Compounds.</p>
                        </div>
                        <div className="quote-lisa-text">
                            <p className="q">Single soluble fertilizers, Soluble NPK, Liquid Fertilizers, Chelated Microelements, Trace elements, Organic Fertilizers, Amino Acids, Compound NPK, Slow Release, Plant Growth Regulator, Humic Acid & Fulvic Acid Ocean sources Fertilizers.</p>
                        </div>
                    </div>
                    </article>
                    <article className="quote-lisa">
                    <h2 style={{fontSize: "xx-large", textDecoration: "underline", fontWeight: "bold"}} className="quote-lisa-cite">Agrochemicals</h2>
                    <div className="quote-lisa-body">
                        <div className="quote-lisa-text">
                            <p className="q">Herbicides, Insecticides, Fungicides, Seed Dressers and Public Health pesticides.</p>
                        </div>
                    </div>
                    </article>
                    <article className="quote-lisa">
                    <h2 style={{fontSize: "xx-large", textDecoration: "underline", fontWeight: "bold"}} className="quote-lisa-cite">Farm tools</h2>
                    <div className="quote-lisa-body">
                        <div className="quote-lisa-text">
                            <p className="q">Knapsack Sprayers.</p>
                        </div>
                    </div>
                    </article>
                    <article className="quote-lisa">
                    <h2 style={{fontSize: "xx-large", textDecoration: "underline", fontWeight: "bold"}} className="quote-lisa-cite">Seeds</h2>
                    <div className="quote-lisa-body">
                        <div className="quote-lisa-text">
                            <p className="q">Alfalfa, Rhodes Grass and Vegetables.</p>
                        </div>
                    </div>
                    </article>
                    <article className="quote-lisa">
                    <h2 style={{fontSize:"xx-large", textDecoration: "underline", fontWeight: "bold"}} className="quote-lisa-cite">Harvesting Consumables</h2>
                    <div className="quote-lisa-body">
                        <div className="quote-lisa-text">
                            <p className="q">Jute Bags, Steel Bailing wires and Rotary Mowers’ Blades or Knifes.</p>
                        </div>
                    </div>
                    </article>
                    <article className="quote-lisa">
                    <h2 style={{fontSize: "xx-large", textDecoration: "underline", fontWeight: "bold"}} className="quote-lisa-cite">Petrol Generators Sets</h2>
                    </article>
                </div>
                </div>
            </div>
            </div>
        </section>
    )
}

export default OurRecentPRoducts;