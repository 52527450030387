import React from 'react'
import { OrderOnlineItem } from './OrderOnlineItem/OrderOnlineItem'

const MostOrderOnline = ({ orderonlinedata, ins }) => {
    return (
        <div>
            <section className="section section-md bg-default">
                <div className="container">
                    <div className="row row-40 justify-content-center">
                        <div className="col-sm-8 col-md-7 col-lg-6 wow fadeInLeft" data-wow-delay="0s">
                            <div className="product-banner"><img src="images/fft-500x500.jpg" alt="" width="570" height="715"/>
                                <div className="product-banner-content">
                                    <div className="product-banner-inner" style={{backgroundColor: "transparent"}}>
                                        <h3 className="text-secondary-1" style={{color: "white"}}>Order</h3>
                                        <h2 className="text-primary" style={{color: "#2c3e50"}}>Online</h2>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-5 col-lg-6">
                            <div className="row row-30 justify-content-center">
                                {/* <OrderOnlineItem product_name="MACRUSH POWDER" product_img="images/img-20200523-wa0003-removebg-p.png" />
                                    <OrderOnlineItem product_img="images/growth25.png" product_name="GROWTH NPK" />
                                    <OrderOnlineItem product_name="NEBRAS" product_img="images/Nebras.png" />
                                    <OrderOnlineItem product_img="images/Humu Green1.png" product_name="HUMU GREEN" /> */}
                                {
                                    orderonlinedata.map((data, index) => {
                                        if(index <= 3)
                                            return <OrderOnlineItem ins={ins} key={index} product_name={data.label} product_img={data.img} />
                                    })
                                }
                                
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    )
}

export default MostOrderOnline;