import React from 'react'
import Card from './Card/Card'
import './DesignedBy.css'

const DesignedBy = () => {
    return (
        <div id="designedby" style={{display: 'flex', flexWrap: 'wrap', justifyContent: 'space-evenly', alignItems: 'center', flexDirection: 'row', paddingTop: '5%', height: '100vh'}}>
            <Card name="Arjun Sunil" email="arjunlodhia122@gmail.com" type="Web Designer & Application Developer" number="+(249) 912537887" profile="https://i.imgur.com/GsTWH9us.jpg" />
            <Card name="Parth Sachin" email="parthlodhia154@gmail.com" type="Software Engineer & Web Designer" number="+(249) 925896350" profile="https://i.imgur.com/hlK7A0Ts.jpg" />
        </div>
    )
}

export default DesignedBy;