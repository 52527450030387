import React from 'react'

const Loader = () => {
    return (
        <div className="preloader">
            <div className="preloader-body">
                <div className="cssload-container"><span></span><span></span><span></span><span></span>
                </div>
            </div>
        </div>
    )
}

export default Loader;