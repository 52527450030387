import React from 'react'

const CartItem = ({ cart_name, cart_img, cart_quantity, cart_item_min, cart_item_max }) => {
    return (
        <div className="cart-inline-item">
            <div className="unit align-items-center">
                <div className="unit-left"><a className="cart-inline-figure" href="#"><img src={cart_img} alt="" width="108" height="100"/></a></div>
                <div className="unit-body">
                    <h6 className="cart-inline-name"><a href="#">{cart_name}</a></h6>
                    <div>
                        <div className="group-xs group-inline-middle">
                            <div className="table-cart-stepper">
                                <input id={cart_name} className="form-input" type="number" data-zeros="true" defaultValue={cart_item_min} min={cart_item_min} max={cart_item_max} />
                            </div>
                            <h4>{cart_quantity}</h4>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default CartItem;