import React from 'react'

const ShowCustomerForm = () => {
  if(window.sessionStorage.product_list !== null && window.sessionStorage.product_list !== undefined) {
    return (
      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', minHeight: '100vh', minWidth: '100vw' }}>
          <div className="col-lg-6">
            <h4 className="text-spacing-50">Customer Form</h4>
            <p>Fill this form to place the order</p>
            <form className="rd-form rd-mailform" data-form-output="form-output-global" data-form-type="contact">
              <div className="row row-14 gutters-14">
                <div className="col-sm-6">
                  <div className="form-wrap">
                    <input className="form-input" id="contact-first-name" type="text" name="name" data-constraints="@Required"/>
                    <label className="form-label" htmlFor="contact-first-name">First Name</label>
                  </div>
                </div>
                <div className="col-sm-6">
                  <div className="form-wrap">
                    <input className="form-input" id="contact-last-name" type="text" name="name" data-constraints="@Required"/>
                    <label className="form-label" htmlFor="contact-last-name">Last Name</label>
                  </div>
                </div>
                <div className="col-12">
                  <div className="form-wrap">
                    <input className="form-input" id="contact-email" type="email" name="email" data-constraints="@Email @Required"/>
                    <label className="form-label" htmlFor="contact-email">E-mail</label>
                  </div>
                </div>
                <div className="col-12">
                  <div className="form-wrap">
                    <input className="form-input" id="contact-num" type="text" name="number" minLength="10" maxLength="15" data-constraints="@Required"/>
                    <label className="form-label" htmlFor="contact-num">Phone Number</label>
                  </div>
                </div>
                <div className="col-12">
                    <label htmlFor="locations">Choose a Location</label>
                  <div className="form-wrap" id="locations">
                      <select id="select-locations">
                          <option value="Khartoum">Khartoum</option>
                          <option value="Madani">Madani</option>
                          <option value="AlDamar">AlDamar</option>
                          <option value="Gadaref">Gadaref</option>
                          <option value="Halfa">Halfa</option>
                          <option value="Kassala">Kassala</option>
                      </select>
                  </div>
                </div>
                <div className="col-12">
                  <div className="form-wrap">
                    <label className="form-label" htmlFor="contact-product-list">Product List</label>
                    <textarea className="form-input" id="contact-product-list" name="product-list" data-constraints="@Required">
                      {window.sessionStorage.product_list}
                    </textarea>
                  </div>
                </div>
              </div>
              <button className="button button-primary button-pipaluk" type="submit" onClick={(event) => {
                const email = document.getElementById("contact-email").value
                const firstName = document.getElementById("contact-first-name").value
                const lastName = document.getElementById("contact-last-name").value
                const product_list = document.getElementById("contact-product-list").value
                const phone_number = document.getElementById("contact-num").value
                
                if(email.length !== 0 && firstName.length !== 0 && lastName.length !== 0 && product_list.length !== 0) {
                  window.sendEmail({
                    To : 'arjunlodhia122@gmail.com',
                    From : email,
                    Subject : "Client Order",
                    Body : `${firstName} ${lastName} from ${document.getElementById("select-locations").options[document.getElementById("select-locations").selectedIndex].text} ${phone_number} \n\n ${product_list}`
                  })
                }
                else {
                  alert("Please complete the form")
                }
               
              }}>Order Now</button>
            </form>
          </div>
      </div>
    )
  }
  else {
    window.location.pathname = "/Home"
  }
}

const CustomerForm = () => {
    document.title = "Green Bash - Customer Form"
    return (
        ShowCustomerForm()
    )
}

export default CustomerForm;