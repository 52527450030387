import React from 'react'

const SliderItem = ({ sb_image, label, description }) => {
    return (
        <div className="swiper-slide context-dark" data-slide-bg={sb_image}>
            <div className="swiper-slide-caption">
                <div className="container">
                    <div className="row justify-content-center justify-content-xxl-start">
                        <div className="col-md-10 col-xxl-6">
                            <div className="slider-modern-box">
                            <h1 className="slider-modern-title"><span data-caption-animate="slideInDown" data-caption-delay="0">{label}</span></h1>
                            <p data-caption-animate="fadeInRight" style={{fontWeight: "bold", color: "white"}} data-caption-delay="400">{description}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default SliderItem;