import React from 'react'
import Preview from './Preview/Preview'
import Content from './Contant/Contant'

const AboutUs = () => {
    document.title = "Green Bash - About Us"
    return (
        <div>
            <Preview />
            <Content />
        </div>
    )
}

export default AboutUs;