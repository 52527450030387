import React from 'react'
import OurFuturePlans from './OurFuturePlans/OurFuturePlans';

const Content = () => {
    return (
        <section className="section section-sm section-first bg-default">
            <div style={{display: "flex", justifyContent: "center", alignItems: "flex-start", flexDirection: "row", padding: "10px"}}>
            {
                <OurFuturePlans />
            }
            </div>
        </section>
    )
}

export default Content;