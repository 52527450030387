import React from 'react'

const Establishment = () => {
    return (
        <section className="section text-center">
            <div className="parallax-container" data-parallax-img="images/Establishment.jpg">
            <div className="parallax-content section-xl context-dark bg-overlay-68">
                <div className="container">
                <h2 className="oh font-weight-normal"><span className="d-inline-block wow slideInDown" data-wow-delay="0s">Establishment</span></h2>
                <p className="oh big text-width-large" style={{color: "white", fontStyle: "oblique"}}><span className="d-inline-block wow slideInUp" data-wow-delay=".2s">To innovate a fresh, modern and ever evolving company for 21st century, to support the Agriculture sector in Sudan which is known to be capable of being basket of food for the whole of Africa and Middle East an energetic company was required in the midst of all what was in the market and thus the birth of Green Bash For Multi Activities Ltd. in July of 2016.</span></p>
                </div>
            </div>
            </div>
        </section>
    )
}

export default Establishment;