import React, { Component } from 'react'

const OurFuturePlansData = async () => {
    return (await fetch('https://greenbashapi.herokuapp.com/ourfutureplans')).json()
}

class OurFuturePlans extends Component {
    constructor(props) {
        super(props)
        this.state = {
            ourfutureplansdata: []
        }
    }

    componentDidMount() {
        Promise.resolve(OurFuturePlansData()).then(result => { this.setState({ ourfutureplansdata: result }) })
    }

    render() {
        return (
            <div style={{display: "flex", flexDirection: "column"}}>
                <h2>Our Future Plans</h2>
                <ol style={{fontWeight: "bolder"}} className="list-ordered">
                    {/* <li>Green houses & Hydroponics systems.</li>
                    <li>Smart farming solutions (GIS, GPS, Farms mapping, Drones, Weather stations, Solar panels, Sensors).</li>
                    <li>Modern Irrigation Systems (Drip Irrigation, Sprinkler Irrigation).</li>
                    <li>Farm Machineries & Spareparts & Implements.</li>
                    <li>Soil, Water & Plant laboratory.</li>
                    <li>Silos & Cold stores.</li>
                    <li>Post harvesting processing facilities.</li> */}
                    {
                        this.state.ourfutureplansdata.map((data, index) => {
                            return <li key={index}>{data.plans}</li>
                        })
                    }
                </ol>
            </div>
        )   
    }
}

export default OurFuturePlans;