import React from 'react'

const OurMission = () => {
    return (
        <section className="section text-center">
            <div className="parallax-container" data-parallax-img="images/background2.png">
            <div className="parallax-content section-xl section-inset-custom-1 context-dark bg-overlay-40">
                <div className="container">
                <h2 className="oh font-weight-normal"><span className="d-inline-block wow slideInDown" data-wow-delay="0s">Our Mission</span></h2>
                <p className="oh big text-width-large" style={{color: "white"}}><span className="d-inline-block wow slideInUp" data-wow-delay=".2s">Our commitments are to provide our clients with the most innovative, sustainable and cost-effective inputs to help them raise their yield level and produce with a degree of high quality.</span></p>
                </div>
            </div>
            </div>
        </section>
    )
}

export default OurMission;