import React from 'react'
import { OrderOnlineItemExtra } from '../MostOrderOnline/OrderOnlineItem/OrderOnlineItem'

const ExtraOrderOnline = ({ orderonlinedata, ins }) => {
    return (
        <section className="section section-md bg-default">
           <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-evenly', alignItems: 'center', flexWrap: 'wrap'}}>
                {
                    orderonlinedata.map((data, index) => {
                        if(index >= 4)
                            return <OrderOnlineItemExtra ins={ins} key={index} product_name={data.label} product_img={data.img} />
                    })
                }
           </div>
        </section>
    )
}

export default ExtraOrderOnline;