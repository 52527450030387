import React from 'react'

const OrderOnlineItem = ({ product_img, product_name, ins }) => {
    return (
        <div className="col-sm-6 col-md-12 col-lg-6">
            <div className="oh-desktop">
                <article className="product product-2 box-ordered-item wow slideInLeft" data-wow-delay="0s">
                    <div className="unit flex-row flex-lg-column">
                        <div className="unit-left">
                            <div className="product-figure"><img src={product_img} alt="" width="270" height="280"/>
                                <div className="product-button"><a onClick={async (event) => {
                                    const data = await (await fetch(`http://greenbashapi.herokuapp.com/cartinfo/${event.target.parentElement.parentElement.parentElement.parentElement.children[1].children[0].children[0].innerHTML}`)).json()
                                    ins.state.cart.push(data[0])
                                    ins.setState({ cart: ins.state.cart })
                                    window.sessionStorage.clear()
                                    const names = ins.state.cart.map(data => {
                                        return data.cart_name
                                    })
                                    window.sessionStorage.cart_list = names
                                }} className="button button-md button-white button-ujarak" href={`#${product_name}`}>Add To Cart</a></div>
                            </div>
                        </div>
                        <div className="unit-body">
                            <h6 className="product-title"><a href={`#${product_name}`}>{product_name}</a></h6>
                        </div>
                    </div>
                </article>
            </div>
        </div>
    )
}

const OrderOnlineItemExtra = ({ product_img, product_name, ins }) => {
    return (
        <div className="owl-item">
            <div className="oh-desktop">
                <article className="product product-2 box-ordered-item wow slideInLeft" data-wow-delay="0s">
                    <div className="unit flex-row flex-lg-column">
                        <div className="unit-left">
                            <div className="product-figure"><img src={product_img} alt="" width="270" height="280"/>
                                <div className="product-button"><a onClick={async (event) => {
                                    const data = await (await fetch(`http://greenbashapi.herokuapp.com/cartinfo/${event.target.parentElement.parentElement.parentElement.parentElement.children[1].children[0].children[0].innerHTML}`)).json()
                                    ins.state.cart.push(data[0])
                                    ins.setState({ cart: ins.state.cart })
                                    window.sessionStorage.clear()
                                    const names = ins.state.cart.map(data => {
                                        return data.cart_name
                                    })
                                    window.sessionStorage.cart_list = names
                                }} className="button button-md button-white button-ujarak" href={`#${product_name}`}>Add To Cart</a></div>
                            </div>
                        </div>
                        <div className="unit-body">
                            <h6 className="product-title"><a href={`#${product_name}`}>{product_name}</a></h6>
                        </div>
                    </div>
                </article>
            </div>
        </div>
    )
}

export { OrderOnlineItem, OrderOnlineItemExtra };