import React from 'react'
import Preview from './Preview/Preview'
import WhyChooseUs from './WhyChooseUs/WhyChooseUs'
import OurPartner from './OurPartner/OurPartner'
import OurRecentProducts from './OurRecentProducts/OurRecentProducts'
import OurGallery from '../Home/OurGallery/OurGallery'

const OurProducts = () => {
    document.title = "Green Bash - Our Products"
    return (
        <div>
            <Preview />
            <WhyChooseUs />
            <OurPartner />
            <OurRecentProducts />
            <OurGallery />
        </div>
    )
}

export default OurProducts;