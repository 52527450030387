import React, { Component } from 'react'
import Navbar from "./Navbar/Navbar"
import Footer from './Footer/Footer'
import Home from './Home/Home'
import OurProducts from './OurProducts/OurProducts'
import ContactUs from './ContactUs/ContactUs'
import AboutUs from './AboutUs/AboutUs'
import DesignedBy from './DesignedBy/DesignedBy'
import CustomerForm from './CustomerForm/CustomerForm'

const CurrentPage = (currentPage, ins) => {
    if(currentPage === 'Home')
        return <Home ins={ins} />
    else if(currentPage === 'OurProducts')
        return <OurProducts />
    else if(currentPage === 'ContactUs')
        return <ContactUs />
    else if(currentPage === 'AboutUs')
        return <AboutUs />
    else
        return <Home ins={ins} />
}

const MainContent = (currentPage, ins, cart) => {
    if(currentPage === 'DesignedBy')
        return <DesignedBy />
    else if(currentPage === 'CustomerForm')
        return <CustomerForm />
    else
        return (
            <div>
                <Navbar cart={cart} ins={ins} />
                {
                    CurrentPage(currentPage, ins)
                }
                <Footer currentPage={currentPage}/>
            </div>
        )

}

const active = [ "Home", "OurProducts", "ContactUs", "AboutUs" ]

class Page extends Component {
    constructor(props) {
        super(props)
        this.state = {
            currentPage: window.location.pathname.split('/')[1],
            cart: []
        }
    }

    componentDidMount() {
        active.forEach((data, index) => {
            if(window.location.pathname.split('/')[1] === data) {
                document.getElementsByClassName("active")[0].classList.remove("active");
                document.getElementsByClassName("rd-nav-item")[index].classList.add("active");
            }
        })
    }

    render() {
        return (
            <div className="page">
                {
                    MainContent(this.state.currentPage, this, this.state.cart)
                }
            </div>
        );
    }
}

export default Page;