import React, { Component } from 'react'
import OurPartnerItem from './OurPartnerItem/OutPartnerItem'

const OurPartnersData = async () => {
    return (await fetch('https://greenbashapi.herokuapp.com/ourpartners')).json()
}

class OurPartner extends Component {
    constructor(props) {
        super(props)
        this.state = {
            ourpartnerdata: []
        }
    }

    componentDidMount() {
        Promise.resolve(OurPartnersData()).then(result => { this.setState({ ourpartnerdata: result }) })
    }

    render() {
        return (
            <section className="section section-md section-fluid bg-default">
                <div className="container">
                <h2>Our Partners</h2>
                </div>
                <div className="owl-carousel owl-classic owl-timeline" data-items="1" data-md-items="2" data-lg-items="3" data-xl-items="4" data-margin="30" data-stage-padding="15" data-xxl-stage-padding="0" data-autoplay="true" data-nav="true" data-dots="true">
                {/* <div className="owl-item">
                    <article className="thumbnail thumbnail-mary thumbnail-md">
                    <div className="thumbnail-mary-figure"><img src="images/Jordan (1).jpg" alt="" width="420" height="308"/>
                    </div>
                    <div className="thumbnail-mary-caption"><a className="icon fl-bigmug-line-zoom60" href="https://i.imgur.com/isaOUMT.jpg" data-lightgallery="item"><img src="images/project-2-420x308.jpg" alt="" width="420" height="308"/></a>
                    </div>
                    </article>
                    <div className="thumbnail-mary-description">
                        <h5 className="thumbnail-mary-project"><a href="#">Jordan</a></h5><span className="thumbnail-mary-decor"></span>
                    </div>
                </div> */}
                <OurPartnerItem name="Jordan" flag_img="images/Jordan (1).jpg" description_img="https://i.imgur.com/isaOUMT.jpg" />
                <OurPartnerItem name="China" flag_img="images/China (1).jpg" description_img="https://i.imgur.com/H9s1I8h.jpg" />
                <OurPartnerItem name="Saudi Arabia" flag_img="images/saudia arabia (2).jpg" description_img="images/text3.jpg" />
                <OurPartnerItem name="Turkey" flag_img="images/Turkey (1).jpg" description_img="images/text4.jpg" />
                <OurPartnerItem name="Australia" flag_img="images/Australia (1).jpg" description_img="https://i.imgur.com/WW8aSAi.jpgg" />
                <OurPartnerItem name="Bangladesh" flag_img="images/Bangladesh (1).jpg" description_img="images/text6.jpg" />
                
                {/* <div className="owl-item">
                    <article className="thumbnail thumbnail-mary thumbnail-md">
                    <div className="thumbnail-mary-figure"><img src="images/China (1).jpg" alt="" width="420" height="308"/>
                    </div>
                    <div className="thumbnail-mary-caption"><a className="icon fl-bigmug-line-zoom60" href="https://i.imgur.com/H9s1I8h.jpg" data-lightgallery="item"><img src="images/project-2-420x308.jpg" alt="" width="420" height="308"/></a>
                    </div>
                    </article>
                    <div className="thumbnail-mary-description">
                        <h5 className="thumbnail-mary-project"><a href="#">China</a></h5><span className="thumbnail-mary-decor"></span>
                    </div>
                </div>
                <div className="owl-item">
                    <article className="thumbnail thumbnail-mary thumbnail-md">
                    <div className="thumbnail-mary-figure"><img src="images/saudia arabia (2).jpg" alt="" width="420" height="308"/>
                    </div>
                    <div className="thumbnail-mary-caption"><a className="icon fl-bigmug-line-zoom60" href="images/text3.jpg" data-lightgallery="item"><img src="images/project-3-420x308.jpg" alt="" width="420" height="308"/></a>
                    </div>
                    </article>
                    <div className="thumbnail-mary-description">
                        <h5 className="thumbnail-mary-project"><a href="#">Saudi Arabia</a></h5><span className="thumbnail-mary-decor"></span>
                    </div>
                </div>
                <div className="owl-item">
                    <article className="thumbnail thumbnail-mary thumbnail-md">
                    <div className="thumbnail-mary-figure"><img src="images/Turkey (1).jpg" alt="" width="420" height="308"/>
                    </div>
                    <div className="thumbnail-mary-caption"><a className="icon fl-bigmug-line-zoom60" href="images/text4.jpg" data-lightgallery="item"><img src="images/project-4-420x308.jpg" alt="" width="420" height="308"/></a>
                    </div>
                    </article>
                    <div className="thumbnail-mary-description">
                        <h5 className="thumbnail-mary-project"><a href="#">Turkey</a></h5><span className="thumbnail-mary-decor"></span>
                    </div>
                </div>
                <div className="owl-item">
                    <article className="thumbnail thumbnail-mary thumbnail-md">
                    <div className="thumbnail-mary-figure"><img src="images/Australia (1).jpg" alt="" width="420" height="308"/>
                    </div>
                    <div className="thumbnail-mary-caption"><a className="icon fl-bigmug-line-zoom60" href="images/text5.jpg" data-lightgallery="item"><img src="images/project-5-420x308.jpg" alt="" width="420" height="308"/></a>
                    </div>
                    </article>
                    <div className="thumbnail-mary-description">
                        <h5 className="thumbnail-mary-project"><a href="#">Australia</a></h5><span className="thumbnail-mary-decor"></span>
                    </div>
                </div>
                <div className="owl-item">
                    <article className="thumbnail thumbnail-mary thumbnail-md">
                    <div className="thumbnail-mary-figure"><img src="images/Bangladesh (1).jpg" alt="" width="420" height="308"/>
                    </div>
                    <div className="thumbnail-mary-caption"><a className="icon fl-bigmug-line-zoom60" href="images/text6.jpg" data-lightgallery="item"><img src="images/project-5-420x308.jpg" alt="" width="420" height="308"/></a>
                    </div>
                    </article>
                    <div className="thumbnail-mary-description">
                        <h5 className="thumbnail-mary-project"><a href="#">Bangladesh</a></h5><span className="thumbnail-mary-decor"></span>
                    </div>
                </div> */}
                {/* {
                    this.state.ourpartnerdata.map((data, index) => {
                        return <OurPartnerItem key={inde} name={data.name} flag_img={data.flag_img} description_img={data.description_img} />
                    })
                } */}
                </div>
            </section>
        )
    }
}

export default OurPartner;