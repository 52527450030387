import React, { Component } from 'react'
import OurTeamItem from './OurTeamItem/OurTeamItem';

const OurTeamData = async () => {
    return (await fetch('https://greenbashapi.herokuapp.com/hometeam')).json()
}

class OurTeam extends Component {
    constructor(props) {
        super(props)
        this.state = {
            ourteamdata: []
        }
    }

    componentDidMount() {
        Promise.resolve(OurTeamData()).then(result => { this.setState({ ourteamdata: result }) })
    }

    render() {
        return (
            <section className="section section-md bg-default">
                <div className="container">
                <div className="oh">
                    <h2 className="wow slideInUp" data-wow-delay="0s">Our Team</h2>
                </div>
                <div className="row row-30 justify-content-center">
                    {/* <OurTeamItem name="Mohammed Ibrahim" profession="General Manager" profile_img="images/profile2.png" />
                    <OurTeamItem name="Yasir Elsir" profession="Finicial Manager" profile_img="images/profile3.png" />
                    <OurTeamItem name="Abdel Halim Hamid" profession="Agriculture Engineer" profile_img="images/Profile1.png" /> */}
                    {
                        this.state.ourteamdata.map((data, index) => {
                            return <OurTeamItem name={data.name} profession={data.type} profile_img={data.profile} key={index} />
                        })
                    }
                </div>
                </div>
            </section>
        )
    }
}

export default OurTeam;