import React from 'react'

const Staff = () => {
    return (
        <div>
            <section className="section section-md section-fluid bg-default">
                <div className="container wow fadeInRight">
                    <h2 style={{color: "#2d3436", textDecoration: "underline"}}>Khartoum Office HQ</h2>
                </div>
                <div className="owl-carousel owl-classic owl-timeline" data-items="1" data-md-items="2" data-lg-items="3" data-xl-items="3" data-margin="30" data-stage-padding="15" data-xxl-stage-padding="0" data-autoplay="true" data-nav="true" data-dots="true">
                    <div className="owl-item wow fadeInLeft">
                        <div className="container">
                            <img src="https://icons-for-free.com/iconfiles/png/512/business+costume+male+man+office+user+icon-1320196264882354682.png" width="200" height="200" />
                            <h3 style={{color: "#1e272e"}}>Mohamed Ibrahim</h3>
                            <h5 style={{color: "#1e272e"}}>General Manager</h5>
                            <h4 style={{color: "#bdc3c7"}}>+249 123 478 930</h4>
                        </div>
                    </div>
                    <div className="owl-item wow fadeInLeft">
                        <div className="container">
                            <img src="https://icons-for-free.com/iconfiles/png/512/business+costume+male+man+office+user+icon-1320196264882354682.png" width="200" height="200" />
                            <h3 style={{color: "#1e272e"}}>Yasir Elsir</h3>
                            <h5 style={{color: "#1e272e"}}>Financial Manager</h5>
                            <h4 style={{color: "#bdc3c7"}}>+249 123 478 935</h4>
                        </div>
                    </div>
                    <div className="owl-item wow fadeInLeft">
                        <div className="container">
                            <img src="https://icons-for-free.com/iconfiles/png/512/business+costume+male+man+office+user+icon-1320196264882354682.png" width="200" height="200" />
                            <h3 style={{color: "#1e272e"}}>Abdel Halim Hamid</h3>
                            <h5 style={{color: "#1e272e"}}>Sales Engineer</h5>
                            <h4 style={{color: "#bdc3c7"}}>+249 123 478 940</h4>
                        </div>
                    </div>
                    <div className="owl-item wow fadeInLeft">
                        <div className="container">
                            <img src="https://icons-for-free.com/iconfiles/png/512/business+costume+male+man+office+user+icon-1320196264882354682.png" width="200" height="200" />
                            <h3 style={{color: "#1e272e"}}>Ahmed Shaikh Eldeen</h3>
                            <h5 style={{color: "#1e272e"}}>Accountant</h5>
                            <h4 style={{color: "#bdc3c7"}}>+249 123 478 934</h4>
                        </div>
                    </div>
                </div>
            </section>

            <section className="section section-md section-fluid bg-default">
                <div className="container wow fadeInRight">
                <h2 style={{color: "#2d3436", textDecoration: "underline"}}>Wad Madani Office</h2>
                </div>
                <div className="owl-carousel owl-classic owl-timeline" data-items="1" data-md-items="2" data-lg-items="3" data-xl-items="2" data-margin="30" data-stage-padding="15" data-xxl-stage-padding="0" data-autoplay="true" data-nav="true" data-dots="true">
                <div className="owl-item wow fadeInLeft">
                    <div className="container">
                        <img src="https://icons-for-free.com/iconfiles/png/512/business+costume+male+man+office+user+icon-1320196264882354682.png" width="200" height="200" />
                        <h3 style={{color: "#1e272e"}}>Massad Babikir Bakhet</h3>
                        <h5 style={{color: "#1e272e"}}>Area Manager & Technical Consultant</h5>
                        <h4 style={{color: "#bdc3c7"}}>+249 123 478 939</h4>
                    </div>
                </div>
                <div className="owl-item wow fadeInLeft">
                    <div className="container">
                        <img src="https://icons-for-free.com/iconfiles/png/512/business+costume+male+man+office+user+icon-1320196264882354682.png" width="200" height="200" />
                        <h3 style={{color: "#1e272e"}}>Bakri Abdulaziz</h3>
                        <h5 style={{color: "#1e272e"}}>Sales Engineer</h5>
                        <h4 style={{color: "#bdc3c7"}}>+249 123 478 931</h4>
                    </div>
                </div>
                </div>
            </section>

            <section className="section section-md section-fluid bg-default">
                <div className="container wow fadeInRight">
                <h2 style={{color: "#2d3436", textDecoration: "underline"}}>AlDamar Office</h2>
                </div>
                <div className="owl-carousel owl-classic owl-timeline" data-items="1" data-md-items="1" data-lg-items="3" data-xl-items="1" data-margin="30" data-stage-padding="15" data-xxl-stage-padding="0" data-autoplay="true" data-nav="true" data-dots="true">
                <div className="owl-item wow fadeInLeft">
                    <div className="container">
                        <img src="https://icons-for-free.com/iconfiles/png/512/business+costume+male+man+office+user+icon-1320196264882354682.png" width="200" height="200" />
                        <h3 style={{color: "#1e272e"}}>Mohamed Abdulrahim</h3>
                        <h5 style={{color: "#1e272e"}}>Sales Engineer</h5>
                        <h4 style={{color: "#bdc3c7"}}>+249 123 478 941</h4>
                    </div>
                </div>
                </div>
            </section>

            <section className="section section-md section-fluid bg-default">
                <div className="container wow fadeInRight">
                <h2 style={{color: "#2d3436", textDecoration: "underline"}}>Gadaref, Halfa & Kassala Office</h2>
                </div>
                <div className="owl-carousel owl-classic owl-timeline" data-items="1" data-md-items="1" data-lg-items="3" data-xl-items="1" data-margin="30" data-stage-padding="15" data-xxl-stage-padding="0" data-autoplay="true" data-nav="true" data-dots="true">
                <div className="owl-item wow fadeInLeft">
                    <div className="container">
                        <img src="https://icons-for-free.com/iconfiles/png/512/business+costume+male+man+office+user+icon-1320196264882354682.png" width="200" height="200" />
                        <h3 style={{color: "#1e272e"}}>Kamlesh Lodhia</h3>
                        <h5 style={{color: "#1e272e"}}>Sales Representative</h5>
                        <h4 style={{color: "#bdc3c7"}}>+249 123 478 938</h4>
                    </div>
                </div>
                </div>
            </section>
        </div>
    )
}

export default Staff;